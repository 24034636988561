import 'twin.macro';
import React from 'react';
import { graphql } from 'gatsby';
import { PrivacyPolicyPageQuery } from '../../graphql-types';

import Layout from '@components/Layout';
import Section from '@components/Section';
import Heading from '@components/Heading';
import Content from '@components/Content';

interface PrivacyPolicyPageProps {
  data: PrivacyPolicyPageQuery;
}

const PrivacyPolicyPage: React.FC<PrivacyPolicyPageProps> = ({ data }) => {
  const {
    homePage: { frontmatter: homepage },
  } = data;
  const {
    privacyPolicyPage: { rawMarkdownBody: privacyPolicy },
  } = data;

  return (
    <Layout
      title={homepage.title}
      description={homepage.description}
      lang={homepage.lang}
      footer={homepage.footer}
      noIndex={true}
    >
      <Section color="yellow" id="start" fullScreen={false}>
        <div tw="pt-36 pb-12">
          <Heading as="h1">Privacy Policy</Heading>
          <div tw="pt-10 pb-36">
            <Content>{privacyPolicy}</Content>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query PrivacyPolicyPage {
    homePage: markdownRemark(frontmatter: { page: { eq: "index" } }) {
      frontmatter {
        description
        footer {
          content
          heading
          columns {
            contact_highlight
            content
            featured_contact
            name
          }
        }
        lang
        title
      }
    }
    privacyPolicyPage: markdownRemark(frontmatter: { page: { eq: "privacy-policy" } }) {
      rawMarkdownBody
    }
  }
`;
